import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RoleDTO, RoleFormDTO } from '../dto/role.dto';
import { Observable } from 'rxjs';
import { RolePermissionFormDTO } from '../dto/role-permission.dto';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

    private apiUrl = environment.apiUrl; // Use the apiUrl from environment

    constructor(private http: HttpClient) {}

    list(
        sortField: string = 'title_en',
        sortOrder: string = 'asc',
        searchQuery?: string
    ): Observable<RoleDTO[]> {
        let params = new HttpParams()
        .set('sort_by', sortField)
        .set('sort_order', sortOrder);

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        return this.http.get<RoleDTO[]>(this.apiUrl + '/roles', {
        params,
        });
    }

    get(id: number): Observable<RoleDTO> {
        return this.http.get<RoleDTO>(`${this.apiUrl}/roles/${id}`);
    }

    addRole(
        role: RoleFormDTO
    ): Observable<RoleFormDTO> {
        return this.http.post<RoleFormDTO>(
        this.apiUrl + '/roles',
        role
        );
    }
    
      updateRole(
        id: number,
        role: RoleFormDTO
      ): Observable<RoleFormDTO> {
        return this.http.put<RoleFormDTO>(
          `${this.apiUrl}/roles/${id}`,
          role
        );
      }
    
      activateRoles(ids: number[]): Observable<any> {
        return this.http.put(`${this.apiUrl}/roles/activate`, { ids: ids });
      }
    
      deactivateRoles(ids: number[]): Observable<any> {
        return this.http.put(`${this.apiUrl}/roles/deactivate`, { ids: ids });
      }
    
      deleteRoles(ids: number[]): Observable<any> {
        return this.http.post(`${this.apiUrl}/roles/delete`, { ids: ids });
      }

      getRolePermission(roleId: number): Observable<any> {
        return this.http.get<any>(
          `${this.apiUrl}/roles/${roleId}/permissions`
        );
      }


      updateRolePermission(roleId: number, permissions: RolePermissionFormDTO[]): Observable<RolePermissionFormDTO[]> {
        return this.http.put<RolePermissionFormDTO[]>(
          `${this.apiUrl}/roles/${roleId}/permissions`, permissions
        );
      }
}
